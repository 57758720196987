@import "main";

header {
  background-color: $background-color;
  height: 64px;

  @media screen and (max-width: $break-size-desktop) {
    height: auto;
  }

  .content_header {
    margin: 0 128px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $break-size-desktop) {
      margin: 0 16px;
    }

    @media screen and (max-width: $break-size-desktop - 256) {
      flex-direction: column;
      padding-bottom: 16px;
    }

    * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #my_name {
      a {
        border-top: 4px solid $border-color-1;
        border-left: 4px solid $border-color-2;
        border-right: 4px solid $border-color-3;
        border-bottom: 4px solid $border-color-4;

        border-radius: 8px;

        font-family: "DOMINE", sans-serif;

        padding: 16px;
        margin: 32px;

        @media screen and (max-width: $break-size-desktop) {
          margin: 16px;
        }

        color: $white-color;
        font-size: 32px;
        text-align: left;
        text-decoration: none;

        @media screen and (max-width: $break-size-desktop) {
          font-size: 24px;
        }

        &:hover {
          color: $gray-color;

          border-top: 4px solid $border-color-2;
          border-left: 4px solid $border-color-3;
          border-right: 4px solid $border-color-4;
          border-bottom: 4px solid $border-color-1;

          transition: all 0.2s ease-in;
        }
      }
    }

    #menu {
      @media screen and (max-width: $break-size-mobile) {
        // flex-direction: column;
      }
      a {
        padding-right: 32px;
        color: $white-color;
        text-decoration: none;
        font-weight: bold;
        text-align: center;
        padding: 16px;

        @media screen and (max-width: $break-size-desktop) {
          font-size: 16px;
        }

        &:hover {
          color: gray;
          transition: all 0.2s ease-in;
        }
      }
    }
  }
}

.current_link {
  border: 2px solid $white-color !important;
}
