@import "../main";

main {
  width: $common-size;
  color: $white-color;
  margin: 128px auto;

  text-align: left;
  line-height: 1.4;

  @media screen and (max-width: $break-size-desktop) {
    width: 90%;
    margin: 64px auto;
  }
}
