@import "main";

footer {
  width: 100%;
  height: 64px;
  margin: 16px 0;

  @media screen and (max-width: $break-size-desktop) {
    height: auto;
    margin-top: 128px;
  }

  .content_footer {
    width: 90%;
    margin: 8px auto;

    display: flex;
    justify-content: space-around;

    @media screen and (max-width: $break-size-desktop) {
      width: 75%;
      flex-direction: column;
      // align-items: center;
    }

    #websited_created {
      display: flex;
      align-items: center;

      @media screen and (max-width: $break-size-desktop) {
        display: block;
        margin: 0 auto;
      }
    }

    #sitemap {
      display: flex;
      align-items: center;

      @media screen and (max-width: $break-size-desktop) {
        margin-bottom: 16px;
      }

      @media screen and (max-width: $break-size-desktop) {
        margin-top: 16px;
        justify-content: space-evenly;
      }

      a {
        color: $white-color;
        margin-left: 16px;
        font-weight: bold;

        @media screen and (max-width: $break-size-desktop) {
          margin-left: 0;
        }
      }
    }

    #icons {
      width: 256px;

      display: flex;
      flex-direction: row;
      justify-content: space-around;

      @media screen and (max-width: $break-size-desktop) {
        padding-bottom: 8px;
      }
      @media screen and (max-width: $break-size-desktop) {
        width: auto;
        justify-content: space-evenly;
      }

      img {
        width: 32px;
        height: 32px;
        color: $white-color;

        @media screen and (max-width: $break-size-desktop) {
          width: 24px;
          height: 24px;
        }
      }
    }

    #website_created {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
