@import "main";

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: auto;
  padding: 16px;
  margin: 64px 32px;

  background-color: $box-background-color;

  border-radius: 12px;

  @media screen and (max-width: $break-size-mobile) {
    padding: 8px;
    margin: 32px 0px;
  }

  .informations {
    flex: 2;
    padding: 8px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: top;
      margin-top: 16px;

      img {
        width: 32px;
        height: 32px;

        @media screen and (max-width: $break-size-mobile) {
          width: 24px;
          height: 24px;
        }
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }

      .name {
        font-weight: bold;
        font-size: 36px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 16px;

        @media screen and (max-width: $break-size-mobile) {
          font-size: 28px;
        }
      }

      .typeAndDate {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media screen and (max-width: 900px) {
          align-items: flex-start;
          margin-left: 16px;
          margin-top: 16px;
        }

        .date {
          font-style: italic;
          padding-top: 8px;
          color: $gray-color;
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            margin-left: 8px;
          }
        }
      }
    }

    .description {
      padding: 16px;

      .technologies {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        flex-wrap: wrap;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 16px;
    }
  }

  .screenshots {
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px;

    .image-gallery-svg {
      height: 16px !important;
    }

    .image-gallery-image {
      border-radius: 4px;
    }
  }
}

.website {
  border: 4px solid $border-color-1;
}

.mobileApp {
  border: 4px solid $border-color-2;
}

.videoGame {
  border: 4px solid $border-color-3;
}

.other {
  border: 4px solid $border-color-4;
}
