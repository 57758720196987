@import "main";

.experience {
  padding: 24px;
  margin: 32px 16px;

  @media screen and (max-width: $break-size-mobile) {
    margin: 32px 0;
  }

  color: $text-color;

  background-color: $box-background-color;

  border: 4px solid $border-color-4;
  border-radius: 12px;

  .header-infos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $break-size-desktop / 2) {
      flex-direction: column;
      align-items: flex-start;
    }

    .entreprise-name {
      margin-bottom: 8px;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        font-weight: bold;
        color: $white-color;
        font-size: 24px;

        @media screen and (max-width: $break-size-mobile) {
          font-size: 18px;
          margin-bottom: 8px;
        }

        img {
          width: 32px;
          margin-right: 16px;
        }
      }
    }
    .entreprise-location {
      font-style: italic;
    }
  }

  .entreprise-duration {
    color: $gray-color;
  }

  .missions {
    margin: 16px 0;
  }
}
