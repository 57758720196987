@import "main";

.react-tabs {
  // -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 4px solid $border-color-4;
    margin: 48px 32px;
    display: flex;
    flex-direction: row;

    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    &--selected {
      background: $border-color-4;
      border-color: $border-color-4;
      color: black;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.websiteTabList {
  border-bottom: 4px solid $border-color-1;
  margin: 48px 32px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $break-size-mobile) {
    margin: 32px 0px;
    text-align: center;
  }

  padding: 0;
}

.mobileAppTabList {
  border-bottom: 4px solid $border-color-2;
  margin: 48px 32px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $break-size-mobile) {
    margin: 32px 0px;
    text-align: center;
  }

  padding: 0;
}

.videoGameTabList {
  border-bottom: 4px solid $border-color-3;
  margin: 48px 32px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $break-size-mobile) {
    margin: 32px 0px;
    text-align: center;
  }

  padding: 0;
}

.otherTabList {
  border-bottom: 4px solid $border-color-4;
  margin: 48px 32px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $break-size-mobile) {
    margin: 32px 0px;
    text-align: center;
  }

  padding: 0;
}

.website {
  background: $border-color-1;
  border-color: $border-color-1;
  color: black;
  border-radius: 5px 5px 0 0;
}

.mobileApp {
  background: $border-color-2;
  border-color: $border-color-2;
  color: black;
  border-radius: 5px 5px 0 0;
}

.videoGame {
  background: $border-color-3;
  border-color: $border-color-3;
  color: black;
  border-radius: 5px 5px 0 0;
}

.other {
  background: $border-color-4;
  border-color: $border-color-4;
  color: black;
  border-radius: 5px 5px 0 0;
}
