@import "../main";

.home-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-around;

  @media screen and (max-width: $break-size-desktop) {
    flex-direction: column;
    justify-content: center;
  }

  .text {
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: right;
    margin-right: 90px;

    @media screen and (max-width: $break-size-desktop) {
      text-align: center;
      margin-right: 0;
    }

    .my-name {
      font-family: "DOMINE", sans-serif;

      font-weight: bold;
      font-size: 80px;
      color: $text-color;
      margin: 0;

      @media screen and (max-width: $break-size-desktop) {
        font-size: 64px;
      }

      @media screen and (max-width: $break-size-mobile) {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $break-size-desktop) {
      padding: 48px;
      width: 100%;
    }
  }

  .separation {
    width: 8px;
    height: 445px;

    border-left: 8px solid;
    border-image: linear-gradient(
        to bottom,
        $border-color-1 33%,
        $border-color-2 33%,
        $border-color-2 66%,
        $border-color-3 66%
      )
      5;

    @media screen and (max-width: $break-size-desktop) {
      width: 445px;
      height: 8px;
      border-top: 8px solid;
      border-left: none;
      border-image: linear-gradient(
          to right,
          $border-color-1 33%,
          $border-color-2 33%,
          $border-color-2 66%,
          $border-color-3 66%
        )
        5;
    }

    @media screen and (max-width: $break-size-mobile) {
      width: 90%;
    }
  }

  .all-activies {
    width: 50%;
    padding: 48px;

    @media screen and (max-width: $break-size-desktop) {
      display: flex;
      align-items: center;
      flex-direction: column;

      width: 100%;

      padding: 0;
    }

    .title {
      margin-top: 32px;
      font-size: 42px;

      @media screen and (max-width: $break-size-desktop) {
        font-size: 32px;
        text-align: center;
      }

      @media screen and (max-width: $break-size-mobile) {
        font-size: 26px;
      }
    }

    .activities-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: left;
      justify-content: space-between;
      margin: 48px 32px;
      padding: 48px;
      height: 100%;

      @media screen and (max-width: $break-size-desktop) {
        margin: 0 8px;
        flex-direction: column;
      }

      @media screen and (max-width: $break-size-mobile) {
        margin-left: 0px;
      }
    }
  }
}
