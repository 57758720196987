@import "main";

.what_i_do {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 16px;
  margin-left: 32px;

  @media screen and (max-width: $break-size-desktop) {
    margin-left: 8px;
    flex-direction: row;
  }

  img {
    width: 64px;
    height: 64px;
  }

  span {
    margin-top: 8px;
    margin-left: 24px;
    font-size: 24px;
    width: 254px;
    text-align: left;

    @media screen and (max-width: $break-size-desktop) {
      font-size: 22px;
      width: auto;
      margin-left: 16px;
    }

    @media screen and (max-width: $break-size-mobile) {
      font-size: 20px;
    }
  }
}
