@import "main";

.link {
  padding: 8px 16px;
  border-radius: 16px;
  border: 2px dotted transparent;
  color: $maroon-color-2;
  background-color: $maroon-color-1;

  text-decoration: none;
  font-weight: bold;

  margin-top: 8px;
  margin-right: 16px;

  min-width: 64px;
  text-align: center;

  &:hover {
    border: 2px solid $maroon-color-2;
    cursor: pointer;
    text-decoration: none;
  }
}
