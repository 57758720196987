@import "main";

.card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: $break-size-desktop) {
    flex-direction: column;
    align-items: center;
  }

  margin: 16px auto;

  .card-header {
    display: flex;
    align-items: center;

    .image {
      height: 284px;
      width: 284px;
      border-radius: 50%;

      border: 4px solid $border-color-4;

      @media screen and (max-width: $break-size-mobile) {
        width: 220px;
        height: 220px;
      }
    }
  }

  .card-line {
    display: flex;
    align-items: center;

    .actual-line {
      width: 192px;
      height: 1px;
      border-top: 4px solid $border-color-4;

      @media screen and (max-width: $break-size-desktop) {
        flex-direction: column;
        align-items: center;

        width: 1px;
        height: 128px;

        border-right: 4px solid $border-color-4;

        border-top: none;
      }
    }
  }

  .card-content {
    width: 60%;
    height: auto;
    color: #dbdbdb;

    @media screen and (max-width: $break-size-desktop) {
      width: 90%;
    }

    background-color: $box-background-color;

    border: 4px solid $border-color-4;
    border-radius: 12px;

    padding: 8px;

    p {
      margin-top: 0;
      margin-bottom: 0;
      padding: 24px;

      @media screen and (max-width: $break-size-mobile) {
        padding: 16px;
      }

      .websiteText {
        box-shadow: inset 0 -1px 0 transparent, inset 0 -5px 0 $border-color-1;
      }

      .mobileAppText {
        box-shadow: inset 0 -1px 0 transparent, inset 0 -5px 0 $border-color-2;
      }

      .videoGameText {
        box-shadow: inset 0 -1px 0 transparent, inset 0 -5px 0 $border-color-3;
      }
    }
  }
}
