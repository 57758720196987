@import "main";

.skill-card {
  display: flex;
  flex-direction: column;
  margin: 32px 16px;

  @media screen and (max-width: $break-size-mobile) {
    margin: 32px 0;
  }

  background-color: $box-background-color;
  // background-color: hsl(20, 4%, 58%);

  border: 4px solid $border-color-4;
  border-radius: 12px;

  .header-title {
    padding: 8px;

    margin-top: 8px;
    margin-left: 8px;

    font-weight: bold;
    font-size: 20px;
  }

  .all-lines {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
    padding: 0px 8px;

    .skill {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      height: 48px;
      max-width: 256px;
      margin-left: 32px;
      padding-right: 32px;
      width: 210px;

      @media screen and (max-width: $break-size-mobile) {
        margin-left: 8px;
      }

      @media screen and (max-width: $break-size-mobile) {
        width: 220px;
      }

      img {
        width: 32px;
      }
      span {
        margin-left: 16px;
      }
    }
  }
}
