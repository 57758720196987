// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Amiri&display=swap");

@import url("https://fonts.googleapis.com/css?family=Domine&display=swap");
@import url("https://fonts.googleapis.com/css?family=Heebo&display=swap");

/** Color palette **/
$white-color: rgb(222, 247, 247);

$maroon-color-1: hsl(37, 100%, 34%);
$maroon-color-2: hsl(37, 50%, 79%);

$background-color: hsl(108, 9%, 11%);

$border-color-1: #b95050;
$border-color-2: #5f9568;
$border-color-3: #5f6595;
$border-color-4: #c4a073;

$a: #231f20;

$text-color: hsl(0, 0%, 86%);
$box-background-color: hsl(0, 4%, 9%);

$gray-color: hsl(0, 5%, 59%);

$common-size: 1200px;
$break-size-desktop: 1100px;
$break-size-mobile: 475px;

$break-size-mid: 780px;

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;

  background-color: $background-color;
  color: $white-color;

  font-size: 18px;

  a {
    // font-style: oblique;
    text-decoration: none;
    color: hsl(18, 34%, 68%);

    &:hover {
      text-decoration: underline;
    }
  }

  // @media screen and (max-width: $break-size-mid) {
  //   font-size: 16px;
  // }

  @media screen and (max-width: $break-size-mobile) {
    font-size: 14px;

    ul {
      margin-left: 4px;
      padding-left: 4px;
    }
  }

  h1 {
    color: white;
    font-family: "DOMINE", sans-serif;
  }
}
